
import axios from 'axios'


export default {

  async sysconfs(context) {
    const url = `/sysconfig/bussines`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('sysconf', JSON.stringify(response.data));
      context.commit('setSysconf', {
        sysconf: response.data
      });

      return response
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
       this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  }, 
   async infoConfig(context) {
     const url = `/sysconfig/info-config`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('info_sysconf', JSON.stringify(response.data));
      context.commit('setInfoSysconf', {
        sysconfInfo: response.data
      });

      return response
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
       this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async trySysconfs(context) {
    const sysconf = JSON.parse(sessionStorage.getItem('sysconf'));

    if (sysconf) {
      await context.commit('setSysconf', {
        sysconf: sysconf
      });
    }
  },
  async listCities(context) {
    const url = `/lists-neighborhood`;
    try {
      const response = await axios.get(url);
      console.log(response.data.status);
      sessionStorage.setItem('lists_neighborhood', JSON.stringify(response.data));
      context.commit('setNeighborhood', {
        neighborhood: response.data
      });

      return response
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async tryListCities(context) {
    const neighborhood = JSON.parse(sessionStorage.getItem('lists_neighborhood'));

    if (neighborhood) {
     await context.commit('setNeighborhood', {
        neighborhood: neighborhood
      });
    }
  },
getfileAccountUpload(context){
   axios.get("/sysconfig_bussines").then((response) => {
                    self.sysconfig_bussines = response.data;
               });
},
dataSysconfAction(context) {
  axios.get("/sysconfig_bussines").then((response) => {
    localStorage.setItem('sysconfData', JSON.stringify(response.data));
    context.commit('sysconfMuttation', {
      sysconf: response.data
    })
  });
},
tryDataSysconfAction(context) {

    const sysconf = localStorage.getItem('sysconfData');
    context.commit('sysconfMuttation', {
      sysconf: JSON.parse(sysconf)
    })

}

}
