export default {
  setListUsers(state, payload){
state.listsUsers = payload.listUser;
  },
  setUser(state, payload){
state.userSession = payload.user;
  }
/*  setToken(state, payload) {
    state.token = payload.token
    state.tokenExpiration = payload.tokenExpiration
    state.tokenRefresh = payload.tokenRefresh
    state.didAutoLogout = false
  },setMenu(state, payload) {
    state.menu = payload.menu
  },
  setAutoLogout(state) {
    state.didAutoLogout = true
  }*/
};