let timer;
import axios from 'axios'

export default {

  async sendLogin(context, payload) {
    //console.log(process.env,process.env.VUE_APP_API)
    const url = '/login-web';
    try {

      const response = await axios.post(url, payload);


      const {data: {token, user,  sysconf,suppliers,numeration}} = response.data;

      console.log(token)
      // this.axios.defaults.headers.common.Authorization = `${token_type} ${access_token}`
      axios.defaults.headers.common.Authorization      = `Bearer ${token}`;

      const expiresIn      = 340025 * 1000;
      const expirationDate = new Date(expiresIn)  ;
      console.log(expirationDate.getTime())
      sessionStorage.setItem('token', token);
      sessionStorage.setItem('user', JSON.stringify(user));/*
      sessionStorage.setItem('customer', JSON.stringify(customer));
      sessionStorage.setItem('products', JSON.stringify(products));*/
      sessionStorage.setItem('sysconf', JSON.stringify(sysconf));
      sessionStorage.setItem('loggedIn', true);
      sessionStorage.setItem('suppliers', JSON.stringify(suppliers));
      sessionStorage.setItem('numeration', JSON.stringify(numeration));
      sessionStorage.setItem('tokenExpiration', JSON.stringify(expirationDate));


     /* timer = setTimeout(function () {
        context.dispatch('autoLogout')
      }, expiresIn)*/

      context.commit('setToken', {
        token:           token,
        tokenExpiration: expirationDate,
      });

      return true
    } catch (error) {
      axios.defaults.headers.common.Authorization = null
      // this.axios.defaults.headers.common.Authorization = null
      const errorLogin                            = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      console.log(errorLogin.message);
      if (errorLogin.message === 'Request failed with status code 404') {

        context.commit('setMessageLogin', {
          message: "Usuario o Cantraseña invalidos"
        });
      }
      throw errorLogin
    }
  },

  tryVeryLogin(context) {

    const token           = sessionStorage.getItem('token');
    const tokenExpiration = sessionStorage.getItem('tokenExpiration');
    const loggedIn = sessionStorage.getItem('loggedIn');
    const expiresIn = +tokenExpiration - new Date().getTime();

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;



    if (token) {
      context.commit('setToken', {
        token:           token,
        tokenExpiration: tokenExpiration,
      })
    }
  },

  async MenuUser(context) {
    const url = `/permisos/menu-user`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('menu', JSON.stringify(response.data));

      context.commit('setMenu', {
        menu: response.data
      });
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },
  async tryMenuUser(context) {

    try {

   let data=   sessionStorage.getItem('menu');

      context.commit('setMenu', {
        menu: JSON.parse(data)
      });
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      if (errorLogin.message === 'Request failed with status code 401') {
        this.dispatch('autoLogout');
      }

      throw errorLogin
    }
  },

  getLogout(context) {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('tokenExpiration');
    sessionStorage.removeItem('data_menus');
    sessionStorage.removeItem('invoicesLists');
    sessionStorage.removeItem('reception_lists');
    sessionStorage.removeItem('productLists');
    sessionStorage.removeItem('menu');
    sessionStorage.removeItem('userSession');
    sessionStorage.removeItem('receptionLists');
    sessionStorage.removeItem('titles');
    sessionStorage.removeItem('edit_menu');
    sessionStorage.removeItem('data_roles');
    sessionStorage.removeItem('customerLists');
    sessionStorage.removeItem('info_sysconf');
    sessionStorage.removeItem('data_users');
    sessionStorage.removeItem('sysconf');
    sessionStorage.removeItem('reception_Lists');
console.log('si llego')
    clearTimeout(timer);

    delete axios.defaults.headers.common.Authorization;
    context.commit('setLogout');
  },

  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');

  }
}
