export default {
  setInvoiceListsPos(state,playload){

    state.invoicesLists = playload.invoicesLists;

  },
  setReceptionsLists(state,playload){

    state.receptionsLists = playload.receptionLists;

  },

};