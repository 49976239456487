export default {
  setSysconf(state, payload) {
    state.sysconf = payload.sysconf;
  },
  setInfoSysconf(state, payload) {
    state.sysconfInfo = payload.sysconfInfo;
  },
  setNeighborhood(state, payload) {
    state.listsNeighborhood = payload.neighborhood;
  },

};
