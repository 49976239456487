
import axios from 'axios'


export default {

  async registerCustomerPos(context, payload) {
    const url = `/store-cliente-tracking`;
    try {
      return await axios.post(url, payload);
    } catch (error) {
      console.log(error)

    }
  },
  async consultCustomer(context, payload) {
    const url = `/edit-customer/`+payload;
    try {
      return await axios.get(url);

    } catch (error) {
      console.log(error)

    }
  },
  async listDatatableCustomers(context) {
    const url = `/customers/get-data-table-customers`;
    try {
      const response = await axios.get(url);
      context.commit('setCustomerDataTableLists', {
        customersDataTableLists: response.data,
      });
      console.log('Accition',response.data)
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
  async listCustomer(context) {
    const url = `/customers/data-customers`;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('customerLists', JSON.stringify(response.data));
      context.commit('setCustomerLists', {
        customersLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListCustomer(context) {
    try {
      const customers = JSON.parse(sessionStorage.getItem('customerLists'));
      if (customers) {
        await    context.commit('setCustomerLists', {
          customersLists: customers,
        });
      }
      return customers
    } catch (error) {
      console.log(error)
    }
  },
  async searchCustomers(context, payload) {
    try {
      const response = await axios.get(payload);
      context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  },
  async pagesCustomers(context, payload) {
    try {
      const response = await axios.get(payload);

      sessionStorage.setItem('customerLists', JSON.stringify(response.data));
   await context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }


}
