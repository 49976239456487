import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
import axios from 'axios'

export default {

  state() {
    return {
      seatLists: null,
    };
  },
  mutations,
  actions,
  getters
}
