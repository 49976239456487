
import axios from 'axios'


export default {

  async sendSeatPrint(context, payload) {
    const url = `/reportes/report-seats`;
    try {

      const response = await axios.post(url,payload);
     // console.log(response);

      return response
    } catch (error) {
      console.log(error)

    }
  },
  async listSeatAccount(context) {
    const url = `/reportes/lists-seats`;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('seatLists', JSON.stringify(response.data));
      context.commit('setSeatListsMutation', {
        seatLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListSeatAccount(context) {
    try {
      const customers = JSON.parse(sessionStorage.getItem('seatLists'));
      if (customers) {
        await    context.commit('setSeatListsMutation', {
          seatLists: customers,
        });
      }
      return customers
    } catch (error) {
      console.log(error)
    }
  },
  async searchCustomers(context, payload) {
    try {
      const response = await axios.get(payload);
      context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  },
  async pagesCustomers(context, payload) {
    try {
      const response = await axios.get(payload);

      sessionStorage.setItem('customerLists', JSON.stringify(response.data));
   await context.commit('setCustomerLists', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }


}
