
import axios from 'axios'


export default {


  async listInvoices(context) {
    const url = `/invoices/data-invoices-de`;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
      context.commit('setInvoiceListsPos', {
        invoicesLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async sendListInvoices(context,payload) {
    const url = payload;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
      context.commit('setInvoiceListsPos', {
        invoicesLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListInvoice(context) {
    try {
      const invoices = JSON.parse(sessionStorage.getItem('invoicesLists'));
      if (invoices) {
        await    context.commit('setInvoiceListsPos', {
          invoicesLists: invoices,
        });
      }
      return invoices;
    } catch (error) {
      console.log(error)
    }
  },
  async listReceptions(context) {
    const url = `/receptions/data-reception`;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('reception_lists', JSON.stringify(response.data));
      context.commit('setReceptionsLists', {
        receptionLists: response.data,
      });
      return response
    } catch (error) {
      console.log(error)
    }
  },
  async tryListReceptions(context) {
    try {
      const reception = JSON.parse(sessionStorage.getItem('reception_lists'));
      console.log(reception);
        await    context.commit('setReceptionsLists', {
          receptionLists: reception,
        });
      return reception;
    } catch (error) {
      console.log(error)
    }
  },

  async pagesInvoices(context, payload) {
    try {
      const response = await axios.get(payload);

      sessionStorage.setItem('invoicesLists', JSON.stringify(response.data));
      await context.commit('setInvoiceListsPos', {
        customersLists: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }


}
