export default {
  getttersSysconf(state) {
    return state.sysconf
  },
  SYSCONFSINFO(state) {
    return state.sysconfInfo
  },
  ListsNeighborhoods(state) {
    return state.listsNeighborhood
  },

}
