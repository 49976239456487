export default {
  setCustomerLists(state,playload){

    state.customerLists = playload.customersLists;

  },
  setCustomerDataTableLists(state,playload){

    state.customersDataTableList = playload.customersDataTableLists;

  }
};
