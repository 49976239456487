export default {
  gettersMenu(state) {
    return state.menu
  },
  token(state) {
    return state.token
  },
  getToken(state) {
    return state.token
  },
  tokenExpiration(state) {
    return state.tokenExpiration
  },
  tokenRefresh(state) {
    return state.tokenRefresh
  },
  isAuthenticated(state) {
    return !!state.token
  },
  didAutoLogout(state) {
    return state.didAutoLogout
  },
  isMessageLogin(state) {
    return state.messageLogin
  }
}