export default {
  gettersTitleMenu(state) {
     return state.titleMenus
  },
  DATAMENUS(state) {
    return state.dataMenus
  },
  DATAROLES(state) {
    return state.dataRoles
  },
  gettersEditMenus(state) {
    return state.editMenu
  },
  DATAUSERS(state) {
    return state.dataUsers
  },
  PERMISSIONUSER(state) {
    console.log("cambio en linea "+state.permissionUser)
    return state.permissionUser
  },
}