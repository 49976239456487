import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

//axios.defaults.baseURL = "http://friendlypos.test/api-web/v2"
axios.defaults.baseURL = "https://friendlypos.net/api-web/v2"
const app = createApp(App)

app.use(router)
    .use(VueAxios, axios)
    .use(store)

app.mount('#app')
