import axios from "axios";

export default {
  /**
   * Title Menú
   * Código de Roles y permisos
   * @param context
   * @returns {Promise<*>}
   */
  async titlesMenu(context) {
    const url = `/roles-permisos/lists-title-menus`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('titleMenus', JSON.stringify(response.data));

      context.commit('setTitlesMenu', {
        titles: response.data
      });

      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryTitlesMenu(context) {
    try {

    let data=  sessionStorage.getItem('titleMenus');

      context.commit('setTitlesMenu', {
        titles: JSON.parse(data)
      });

      return response.data
    } catch (error) {
      return (
        error.message
      )

    }
  },
  /***
   * Menú
   * @param context
   * @param payload
   * @returns {Promise<*>}
   */

  async searchMenuPermissions(context, payload) {
    const url = `/user/search-menus-permission`;
    try {
      const response = await axios.post(url,payload);
      sessionStorage.setItem('permissionUser', JSON.stringify(response.data.data));
    await  context.commit('setPermissionUser', {
        permission: response.data.data
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },

  /**
   *
   * @param context
   * @returns {Promise<boolean>}
   */
  async trySearchMenuPermissions(context) {

    try {

      const permission = JSON.parse(sessionStorage.getItem('permissionUser'));
    await  context.commit('setPermissionUser', {
        permission: permission
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async userMenuPermissions(context,payload) {

    const url = `/user/user-menus-permission`;
    try {
      const response = await axios.post(url,payload);
      sessionStorage.setItem('permissionUser', JSON.stringify(response.data.data));
      await  context.commit('setPermissionUser', {
        permission: response.data.data
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async tryUserMenuPermissions(context) {

    try {
     let data = sessionStorage.getItem('permissionUser');
      await  context.commit('setPermissionUser', {
        permission: JSON.parse(data)
      });
      return true;
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'No Tiene permisos para esta parte del sistema'
      )
      throw errorLogin
    }
  },
  async registerTitle(context, payload) {
    const url = `/roles-permisos/store-title-menu`;
    try {
      const response = await axios.post(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async storeRole(context, payload) {
    const url = `/roles-permisos/store-role`;
    try {
      const response = await axios.post(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async updateRole(context, payload) {
    const url = `/roles-permisos/update-role/`+payload.id;
    try {
      const response = await axios.put(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },

  async storeMenu(context, payload) {
    const url = `/roles-permisos/store-menu`;
    try {
      const response = await axios.post(url, payload);
      await store.dispatch('menuUser')
      await store.dispatch('tryMenuUser')
      return response.data

    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async updateMenu(context, payload) {
    const url = `/roles-permisos/update-menu/`+payload.id;
    try {
      const response = await axios.put(url, payload);


      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async editMenu(context, payload) {
    const url = `/roles-permisos/get-menu/`+payload;
    try {
      const response = await axios.get(url);
      sessionStorage.setItem('edit_menu', JSON.stringify(response.data));
      context.commit('setEditMenu', {
        menu: response.data
      });

      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryEditMenu(context) {

    try {

      let data = sessionStorage.getItem('edit_menu');
      context.commit('setEditMenu', {
        menu: JSON.parse(data)
      });

      return data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async menuLists(context) {
    const url = `/roles-permisos/data-menus`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('data_menus', JSON.stringify(response.data));
      context.commit('setDataMenu', {
        menus: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async userLists(context) {
    const url = `/user/data-users`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('data_users', JSON.stringify(response.data));
      context.commit('setDataUser', {
        users: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryUsersLists(context) {
    try {
     // console.log('Se activo')
      const users = JSON.parse(sessionStorage.getItem('data_users'));
      if (users) {
        context.commit('setDataUser', {
          users: users
        });
      }
      return users
    } catch (error) {
      const errorListMenu = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },
  async tryMenuLists(context) {
    try {
  //    console.log('Se activo')
      const menus = JSON.parse(sessionStorage.getItem('data_menus'));
      if (menus) {
        context.commit('setDataMenu', {
          menus: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
        error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },

  async roleLists(context) {
    const url = `/roles-permisos/data-roles`;
    try {
      const response = await axios.get(url);

      sessionStorage.setItem('data_roles', JSON.stringify(response.data));
      context.commit('setDataRole', {
        roles: response.data
      });
      return response.data
    } catch (error) {

      const errorLogin = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorLogin
    }
  },
  async tryRoleLists(context) {
    try {

      const menus = JSON.parse(sessionStorage.getItem('data_roles'));
      if (menus) {
        context.commit('setDataRole', {
          roles: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },
  async tryTitleLists(context) {
    try {
      const menus = JSON.parse(sessionStorage.getItem('titleMenus'));
      if (menus) {
        context.commit('setTitlesMenu', {
          titles: menus
        });
      }
      return menus
    } catch (error) {
      const errorListMenu = new Error(
          error.message || 'Failed to authenticate. Check your login data.'
      )
      throw errorListMenu
    }
  },
  async searchMenus(context, payload) {
    try {
      const response = await axios.get(payload);
      sessionStorage.setItem('data_menus', JSON.stringify(response.data));
      context.commit('setDataMenu', {
        menus: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  },
  async pagesMenus(context, payload) {
    try {
      const response = await axios.get(payload);
      sessionStorage.setItem('data_menus', JSON.stringify(response.data));
      await context.commit('setDataMenu', {
        menus: response.data
      });
      return response
    } catch (error) {
      console.log(error)

    }
  }

}
