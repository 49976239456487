import axios from "axios";

export default {

 getListInventryTemp(context){
  axios.get('/list-temporal-inventario').then((response) => {
return response;
   });
}

}